/*csslint box-model:false*/

$form-disabled-background-color-dark: rgba(white, 0.1) !default;
$form-disabled-background-color: #eaeded !default;
$form-disabled-color-dark: inherit !default;
$form-disabled-color: #cad2d3 !default;
$form-focus-border-color: #129FEA !default;
$form-input-background-color-dark: inherit !default;
$form-input-background-color: inherit !default;
$form-input-color-dark: inherit !default;
$form-input-color: inherit !default;
$form-invalid-border-color: #e9322d !default;
$form-invalid-color: #b94a48 !default;
$form-legend-border-color: #e5e5e5 !default;
$form-legend-color: #333 !default;
$form-message-color: #666 !default;
$form-readonly-background-color: #eee !default;
$form-readonly-border-color: #ccc !default;
$form-readonly-color: #777 !default;
$form-select-background-color: white !default;
$form-select-border-color: #ccc !default;

/*
Box-model set to false because we're setting a height on select elements, which
also have border and padding. This is done because some browsers don't render
the padding. We explicitly set the box-model for select elements to border-box,
so we can ignore the csslint warning.
*/

.pure-form input[type="text"],
.pure-form input[type="password"],
.pure-form input[type="email"],
.pure-form input[type="url"],
.pure-form input[type="date"],
.pure-form input[type="month"],
.pure-form input[type="time"],
.pure-form input[type="datetime"],
.pure-form input[type="datetime-local"],
.pure-form input[type="week"],
.pure-form input[type="number"],
.pure-form input[type="search"],
.pure-form input[type="tel"],
.pure-form input[type="color"],
.pure-form select,
.pure-form textarea {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: $form-input-background-color;
    color: $form-input-color;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form input:not([type]) {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}


/* Chrome (as of v.32/34 on OS X) needs additional room for color to display. */
/* May be able to remove this tweak as color inputs become more standardized across browsers. */
.pure-form input[type="color"] {
    padding: 0.2em 0.5em;
}


.pure-form input[type="text"]:focus,
.pure-form input[type="password"]:focus,
.pure-form input[type="email"]:focus,
.pure-form input[type="url"]:focus,
.pure-form input[type="date"]:focus,
.pure-form input[type="month"]:focus,
.pure-form input[type="time"]:focus,
.pure-form input[type="datetime"]:focus,
.pure-form input[type="datetime-local"]:focus,
.pure-form input[type="week"]:focus,
.pure-form input[type="number"]:focus,
.pure-form input[type="search"]:focus,
.pure-form input[type="tel"]:focus,
.pure-form input[type="color"]:focus,
.pure-form select:focus,
.pure-form textarea:focus {
    outline: 0;
    border-color: $form-focus-border-color;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form input:not([type]):focus {
    outline: 0;
    border-color: $form-focus-border-color;
}

.pure-form input[type="file"]:focus,
.pure-form input[type="radio"]:focus,
.pure-form input[type="checkbox"]:focus {
    outline: thin solid $form-focus-border-color;
    outline: 1px auto $form-focus-border-color;
}
.pure-form .pure-checkbox,
.pure-form .pure-radio {
    margin: 0.5em 0;
    display: block;
}

.pure-form input[type="text"][disabled],
.pure-form input[type="password"][disabled],
.pure-form input[type="email"][disabled],
.pure-form input[type="url"][disabled],
.pure-form input[type="date"][disabled],
.pure-form input[type="month"][disabled],
.pure-form input[type="time"][disabled],
.pure-form input[type="datetime"][disabled],
.pure-form input[type="datetime-local"][disabled],
.pure-form input[type="week"][disabled],
.pure-form input[type="number"][disabled],
.pure-form input[type="search"][disabled],
.pure-form input[type="tel"][disabled],
.pure-form input[type="color"][disabled],
.pure-form select[disabled],
.pure-form textarea[disabled] {
    cursor: not-allowed;
    background-color: $form-disabled-background-color;
    color: $form-disabled-color;

    [theme=dark] & {
        background-color: $form-disabled-background-color-dark;
        color: $form-disabled-color-dark;
    }
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form input:not([type])[disabled] {
    cursor: not-allowed;
    background-color: $form-disabled-background-color;
    color: $form-disabled-color;

    [theme=dark] & {
        background-color: $form-disabled-background-color-dark;
        color: $form-disabled-color-dark;
    }
}
.pure-form input[readonly],
.pure-form select[readonly],
.pure-form textarea[readonly] {
    background-color: $form-readonly-background-color; /* menu hover bg color */
    color: $form-readonly-color; /* menu text color */
    border-color: $form-readonly-border-color;
}

.pure-form input:focus:invalid,
.pure-form textarea:focus:invalid,
.pure-form select:focus:invalid {
    color: $form-invalid-color;
    border-color: $form-invalid-border-color;
}
.pure-form input[type="file"]:focus:invalid:focus,
.pure-form input[type="radio"]:focus:invalid:focus,
.pure-form input[type="checkbox"]:focus:invalid:focus {
    outline-color: $form-invalid-border-color;
}
.pure-form select {
    /* Normalizes the height; padding is not sufficient. */
    height: 2.25em;
    border: 1px solid $form-select-border-color;
    background-color: $form-select-background-color;
}
.pure-form select[multiple] {
    height: auto;
}
.pure-form label {
    margin: 0.5em 0 0.2em;
}
.pure-form fieldset {
    margin: 0;
    padding: 0.35em 0 0.75em;
    border: 0;
}
.pure-form legend {
    display: block;
    width: 100%;
    padding: 0.3em 0;
    margin-bottom: 0.3em;
    color: $form-legend-color;
    border-bottom: 1px solid $form-legend-border-color;
}

.pure-form-stacked input[type="text"],
.pure-form-stacked input[type="password"],
.pure-form-stacked input[type="email"],
.pure-form-stacked input[type="url"],
.pure-form-stacked input[type="date"],
.pure-form-stacked input[type="month"],
.pure-form-stacked input[type="time"],
.pure-form-stacked input[type="datetime"],
.pure-form-stacked input[type="datetime-local"],
.pure-form-stacked input[type="week"],
.pure-form-stacked input[type="number"],
.pure-form-stacked input[type="search"],
.pure-form-stacked input[type="tel"],
.pure-form-stacked input[type="color"],
.pure-form-stacked input[type="file"],
.pure-form-stacked select,
.pure-form-stacked label,
.pure-form-stacked textarea {
    display: block;
    margin: 0.25em 0;
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/
.pure-form-stacked input:not([type]) {
    display: block;
    margin: 0.25em 0;
}
.pure-form-aligned input,
.pure-form-aligned textarea,
.pure-form-aligned select,
.pure-form-message-inline {
    display: inline-block;
    vertical-align: middle;
}
.pure-form-aligned textarea {
    vertical-align: top;
}

/* Aligned Forms */
.pure-form-aligned .pure-control-group {
    margin-bottom: 0.5em;
}
.pure-form-aligned .pure-control-group label {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    width: 10em;
    margin: 0 1em 0 0;
}
.pure-form-aligned .pure-controls {
    margin: 1.5em 0 0 11em;
}

/* Rounded Inputs */
.pure-form input.pure-input-rounded,
.pure-form .pure-input-rounded {
    border-radius: 2em;
    padding: 0.5em 1em;
}

/* Grouped Inputs */
.pure-form .pure-group fieldset {
    margin-bottom: 10px;
}
.pure-form .pure-group input,
.pure-form .pure-group textarea {
    display: block;
    padding: 10px;
    margin: 0 0 -1px;
    border-radius: 0;
    position: relative;
    top: -1px;
}
.pure-form .pure-group input:focus,
.pure-form .pure-group textarea:focus {
    z-index: 3;
}
.pure-form .pure-group input:first-child,
.pure-form .pure-group textarea:first-child {
    top: 1px;
    border-radius: 4px 4px 0 0;
    margin: 0;
}
.pure-form .pure-group input:first-child:last-child,
.pure-form .pure-group textarea:first-child:last-child {
    top: 1px;
    border-radius: 4px;
    margin: 0;
}
.pure-form .pure-group input:last-child,
.pure-form .pure-group textarea:last-child {
    top: -2px;
    border-radius: 0 0 4px 4px;
    margin: 0;
}
.pure-form .pure-group button {
    margin: 0.35em 0;
}

.pure-form .pure-input-1 {
    width: 100%;
}
.pure-form .pure-input-3-4 {
    width: 75%;
}
.pure-form .pure-input-2-3 {
    width: 66%;
}
.pure-form .pure-input-1-2 {
    width: 50%;
}
.pure-form .pure-input-1-3 {
    width: 33%;
}
.pure-form .pure-input-1-4 {
    width: 25%;
}

/* Inline help for forms */
.pure-form-message-inline {
    display: inline-block;
    padding-left: 0.3em;
    color: $form-message-color;
    vertical-align: middle;
    font-size: 0.875em;
}

/* Block help for forms */
.pure-form-message {
    display: block;
    color: $form-message-color;
    font-size: 0.875em;
}

.pure-form .required {
    font-size: smaller;
}

/* Responsive styles */
@media only screen and (max-width : 480px) {
    .pure-form button[type="submit"] {
        margin: 0.7em 0 0;
    }

    .pure-form input:not([type]),
    .pure-form input[type="text"],
    .pure-form input[type="password"],
    .pure-form input[type="email"],
    .pure-form input[type="url"],
    .pure-form input[type="date"],
    .pure-form input[type="month"],
    .pure-form input[type="time"],
    .pure-form input[type="datetime"],
    .pure-form input[type="datetime-local"],
    .pure-form input[type="week"],
    .pure-form input[type="number"],
    .pure-form input[type="search"],
    .pure-form input[type="tel"],
    .pure-form input[type="color"],
    .pure-form label {
        margin-bottom: 0.3em;
        display: block;
    }

    .pure-group input:not([type]),
    .pure-group input[type="text"],
    .pure-group input[type="password"],
    .pure-group input[type="email"],
    .pure-group input[type="url"],
    .pure-group input[type="date"],
    .pure-group input[type="month"],
    .pure-group input[type="time"],
    .pure-group input[type="datetime"],
    .pure-group input[type="datetime-local"],
    .pure-group input[type="week"],
    .pure-group input[type="number"],
    .pure-group input[type="search"],
    .pure-group input[type="tel"],
    .pure-group input[type="color"] {
        margin-bottom: 0;
    }

    .pure-form-aligned .pure-control-group label {
        margin-bottom: 0.3em;
        text-align: left;
        display: block;
        width: 100%;
    }

    .pure-form-aligned .pure-controls {
        margin: 1.5em 0 0 0;
    }

    .pure-form-message-inline,
    .pure-form-message {
        display: block;
        font-size: 0.75em;
        /* Increased bottom padding to make it group with its related input element. */
        padding: 0.2em 0 0.8em;
    }
}