$primary-color: #de641a;

// ==============================
// Variables
// ==============================

// ========== Global ========== //
// Font and Line Height
// $global-font-family: system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !default;
// $global-font-size: 16px;
// $global-font-weight: 400;
// $global-line-height: 1.5rem;

// Color of the background
// $global-background-color: #fff !default;
// $global-background-color-dark: #292a2d !default;

// Color of the text
// $global-font-color: #161209 !default;
// $global-font-color-dark: #a9a9b3 !default;

// Color of the secondary text
// $global-font-secondary-color: #a9a9b3 !default;
// $global-font-secondary-color-dark: #5d5d5f !default;

// Color of the link
// $global-link-color: #161209 !default;
// $global-link-color-dark: #a9a9b3 !default;

// Color of the hover link
$global-link-hover-color: $primary-color;
$global-link-hover-color-dark: $primary-color;

// Color of the border
// $global-border-color: #f0f0f0 !default;
// $global-border-color-dark: #363636 !default;
// ========== Global ========== //

// ========== Scrollbar ========== //
// Color of the scrollbar
// $scrollbar-color: #87878d !default;

// Color of the hover scrollbar
// $scrollbar-hover-color: #a9a9b3 !default;
// ========== Scrollbar ========== //

// ========== Selection ========== //
// Color of the selected text
$selection-color: rgba($primary-color, 0.25);
$selection-color-dark: rgba($primary-color, 0.4);
// ========== Selection ========== //

// ========== Header ========== //
// Height of the header
// $header-height: 3.5rem !default;

// Color of the header background
// $header-background-color: #f8f8f8 !default;
// $header-background-color-dark: #252627 !default;

// Font style of the header title
// $header-title-font-family: $global-font-family !default;
// $header-title-font-size: 1.5rem !default;

// Color of the hover header item
// $header-hover-color: #161209 !default;
// $header-hover-color-dark: #fff !default;

// Color of the search background
// $search-background-color: #e9e9e9 !default;
// $search-background-color-dark: #363636 !default;
// ========== Header ========== //

// ========== Single Content ========== //
// Font size of the TOC
// $toc-title-font-size: 1.2rem !default;
// $toc-content-font-size: 1rem !default;

// Color of the single link
$single-link-color: $primary-color;
$single-link-color-dark: $primary-color;

// Color of the hover single link
$single-link-hover-color: $primary-color;
$single-link-hover-color-dark: $primary-color;

// Color of the table background
// $table-background-color: #fff !default;
// $table-background-color-dark: #272c34 !default;

// Color of the table thead
// $table-thead-color: #ededed !default;
// $table-thead-color-dark: #20252b !default;

// Color of the blockquote
// $blockquote-color: #6bd6fd !default;
// $blockquote-color-dark: #59c5ec !default;
// ========== Single Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
// $pagination-link-color: #2d96bd !default;
// $pagination-link-color-dark: #a9a9b3 !default;

// Color of the hover link in pagination
// $pagination-link-hover-color: #000 !default;
// $pagination-link-hover-color-dark: #fff !default;
// ========== Pagination ========== //

// ========== Code ========== //
// Color of the code
// $code-color: #E74C3C !default;
// $code-color-dark: #E5BF78 !default;

// Color of the code background
// $code-background-color: #f5f5f5 !default;
// $code-background-color-dark: #272C34 !default;

// $code-info-color: #9c9c9c !default;
// $code-info-color-dark: #b1b0b0 !default;

// Font size of the code
// $code-font-size: .875rem !default;

// Font family of the code
// $code-font-family: Source Code Pro, Menlo, Consolas, Monaco, monospace, $global-font-family !default;
// ========== Code ========== //