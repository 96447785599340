@mixin link($light, $dark) {
    a, a::before, a::after {
        text-decoration: none;
  
        color: if($light, $global-link-color, $single-link-color);
  
        [theme=dark] & {
            color: if($dark, $global-link-color-dark, $single-link-color-dark);
        }
    }
  
    a:active,
    a:hover {
        @if $light {
            color: $global-link-hover-color;
        }
        @else {
            text-decoration: underline;
            color: $single-link-hover-color;
        }
  
        [theme=dark] & {
            @if $dark {
                color: $global-link-hover-color-dark;
            }
            @else {
                text-decoration: underline;
                color: $single-link-hover-color-dark;
            }
        }
    }
  }
  